<template>
  <section>
    <div class="content-header">
      <h2>Helping students thrive</h2>
    </div>
    <div class="content-wrapper">
      <p>Check out the journal article, <a href="https://www.science.org/doi/10.1126/sciadv.aba4677" target="_blank">A customized belonging intervention improves retention of socially disadvantaged students at a broad-access university</a> to learn more about community resilience from the experts.</p>
      <!-- <img src="@/assets/img/_content/community-discuss-02.png" class="img-fluid img-thumb" alt="" srcset=""> -->

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
